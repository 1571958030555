import React from 'react'

function ModalThree() {
  return (
    <div className="modal__content">
      Coming Soon
    </div>
  )
}

export default ModalThree